*,
*::before,
*::after {
    box-sizing: border-box;
}


:root {
    --ff-primary: 'Lora', serif;
    --ff-secondary: 'Roboto Slab', serif;
   
    --fw-reg: 400;
    --fw-bold: 700;
    --clr-light: #fff;
    --clr-dark: #303030;
    --clr-accent: #f8333c;
    --clr-footer: #111;
   
    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);
}

html {
    scroll-behavior: smooth;
}

    h1,
    h2,
    h3 {
        line-height: 1;
        margin: 0;
    }
   
    h1 {font-size: clamp(2.1rem, 5.5vw, 7rem)}
    h2 {font-size: clamp(2rem, 4.6vw, 3.75rem)}
    h3 {font-size: clamp(1.8rem, 2.7vw, 2.25rem)}
   
    body {
        margin: 0;
        font-size: clamp(1.3rem, 1.7vw, 2.1rem);
        font-family: var(--ff-primary);
    }

    /*Logo*/
    #logo {
        margin: auto;
        width: 8.5em;
        height: 6em;
        padding: 0;
    }

    #logo-image img {
        width: 7vw;
        padding-bottom: .5em;
    }

    #logo-description {
        position: relative;
        perspective: 6em;
    }

    .logo-description {
        text-transform: uppercase;
    }

    #logo-software {
        position: absolute;
        height: 3em;
        top: -1em;
        left: 0;
        width: 100%;
        font-size: clamp(1rem, 1.75vw, 2.3rem);
        transform: rotateX(45deg);
    }

    #logo-developer {
        position: absolute;
        height: 3em;
        top: 0;
        left: .1em;
        width: 100%;
        font-size: clamp(1rem, 1.75vw, 2.3rem);
        letter-spacing: .3rem;
        transform: rotateX(45deg);
    }
    /*End of logo*/

    /* header */
    header {
        width: 100%;
        padding: 1em;
        height: 8em;
        background: var(--clr-dark);
        color: var(--clr-light);
    }

    #header-grid-container {
        display: grid;
        grid-template-columns: 15% 85%;
        grid-template-rows: 50% 50%;
    }

    #header-grid-bsd {
        text-align: right;
    }

    #header-grid-bsd img {
        height: clamp(1.5em, 2.5vw, 1.9em);
        width: auto;
    }

    #header-grid-nav {
        position: relative;
        width: 100%;
        grid-area: 2 / 2 / 3 / 4;
    }

    .nav-toggle { display: none; } /* disable mobile nav */

    .nav {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        max-width: 60em;
        padding: 0;
    }

    .nav-fixed {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        background: var(--clr-dark);
        z-index: 100;
    }
    
    .nav__list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 4em;
    }
    
    .nav__item {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        position: relative;
        height: 2.6em;
        width: 100%;
    }

    .nav__icon {
        position: absolute;
        width: 100%;
        top: 0;
        font-size: clamp(.9rem, 1.4vw, 2.2rem);
        color: rgb(48, 48, 48, 0);
        transition: color .6s ease-in;
    }

    .nav__link {
        position: absolute;
        bottom: 0;
        text-decoration: none;
        color: var(--clr-light);
        font-size: clamp(.6rem, 1.4vw, 2.2rem);
        letter-spacing: .125em;
        text-transform: uppercase;
    }

    .nav__link:hover+.nav__icon,
    .nav__link:focus+.nav__icon {
        color: rgba(248, 51, 60, 1.0);
    }

    .nav__link:hover,
    .nav__link:focus {
        color: var(--clr-accent);
    }
    
    .nav__link::before {
        content: ' ';
        position: absolute;
        width: 0;
        height: .125em;
        left: 50%;
        bottom: -.1em;
        background-color: var(--clr-accent);
        transition: width .4s linear;
    }
    
    .nav__link::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: .125em;
        right: 50%;
        bottom: -.1em;
        background-color: var(--clr-accent);
        transition: width .4s linear;
    }

    .nav__link:hover::before, 
    .nav__link:focus::before {
        width: 50%;
    }

    .nav__link:hover::after , 
    .nav__link:focus::after {
        width: 50%;
    }

    .nav-fixed a.active, .nav-fixed a.active+.nav__icon,
    .nav a.active, .nav a.active+.nav__icon {
        color: var(--clr-accent);
    }

    .nav-fixed a.active.nav_link_underline::before, 
    .nav a.active.nav_link_underline::before {
        content: ' ';
        position: absolute;
        width: 50%;
        height: .125em;
        left: 50%;
        bottom: -.1em;
        background-color: var(--clr-accent);
        transition: width .4s linear;
    }

    .nav-fixed a.active.nav_link_underline::after,
    .nav a.active.nav_link_underline::after {
        content: ' ';
        position: absolute;
        width: 50%;
        height: .125em;
        right: 50%;
        bottom: -.1em;
        background-color: var(--clr-accent);
    }

    #nav_logo_link {
        color: white;
    }

    #nav_logo_link:active {
        color: var(--clr-accent);
    }

    /* End of Header and Nav */

    .intro {
        min-height: 82vh;
        width: 100%;
    }
    
    #intro-container {
        display: grid;
        grid-template-columns: 35% 1fr;
        grid-template-rows: 60% 1fr;
        margin: 10vh auto;
        width: clamp(17em, 60vw, 50em);
        /*aspect-ratio: 2 / .925; */
    }

    .section__title--intro {
        grid-area: 1 / 2 / 1 / 3;
        font-weight: var(--fw-reg);
        margin-left: .75em;
    }

    .section__title--intro strong {
        display: block;
        font-weight: var(--fw-bold);
    }

    .section__subtitle--intro {
        grid-area: 2 / 1 / 3 /4;
        background: var(--clr-accent);
        font-family: var(--ff-secondary);
        font-size: clamp(1.3rem, 1.8vw, 2.75rem);
        width: 100%;
        height: 1.6em;
        padding: .4em 1em;
        text-align: right;
        z-index: -1;
    }

    .intro__img {
        grid-area: 1 / 1 / 3 / 2;
        box-shadow: var(--bs);
        width: 100%;
        margin-left: clamp(.85em, 2.2vw, 1.5em);
    }

    .my-services {
        width: 100%;
        padding: 1em 0;
        min-height: 100vh;
        background-image: url(../public/img/services-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        color: var(--clr-light);
        text-align: center;
    }

    .services {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: min(2em, 3em);
        line-height: 1.6;
        width: min(90%, 70em);
        padding: 1em 1em;
        min-height: 70vh;
        margin: auto;
    }

    .service {
        width: 100%;
        box-shadow: .125em .125em .5em .125em var(--clr-light);
        text-align: justify;
        background: rgba(48, 48, 48, .3);
        border: 1px solid var(--clr-dark)
    }

    .service h3 {
        margin: auto;
        padding: .5em;
        text-align: center;
        font-size: clamp(1.4rem, 2vw, 2.1rem);
        background: rgba(48, 48, 48, .5);
    }

    .service p {
        width: 100%;
        padding: 0 1.5em;
    }

    .about-me {
        width: 100%;
        padding: .5em;
        min-height: 100vh;
    }
    
    .section__title--about, .section__title--work, .section__title--services {
        padding: .5em 0 0 0;
        color: var(--clr-accent);
    }
    
    .section__subtitle {
        font-size: clamp(1.5rem, 1.6vw, 2.5rem);
        color: var(--clr-accent);
        margin-bottom: 1.5em;
    }
    
    .about-me__body {
        margin: auto;
        max-width: 30em;
        line-height: 1.8;
    }
    
    .my-work {
        background-color: var(--clr-dark);
        color: var(--clr-light);
        text-align: center;
        padding-bottom: 2em;   
    }

    .portfolio {
        display: grid;
        padding: 1.5em;
        gap: 0 1.5em;
        /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
        grid-template-columns: auto auto auto auto;
    }

     .portfolio-card {
        margin-bottom: 2em;
        overflow: hidden;
        border-radius: .5em .75em 0 0;
    }
   
    .portfolio-img-container {
        margin-bottom: .2em;
        overflow: hidden;
    }

    .portfolio__img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        transition:
            transform 750ms cubic-bezier(.5, 0, .5, 1),
            opacity 250ms linear;
    }

    .portfolio__img:hover,
    .portfolio__item:focus .portfolio__img {
        transform: scale(1.2);
        opacity: .5;
    }
       
    .testimonial {
        width: 100%;
        height: 5em;
        text-align: left;
        padding: .5em;
        background:#fbf9f4;
        color: var(--clr-dark);
    }

    /* footer */
    .footer {
        background: var(--clr-footer);
        color: var(--clr-accent);
        padding: 2.5em 0 0 0;
        font-size: clamp(.8rem, 1.4vw, 1.125rem);
    }
    
    .footer-grid-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: left;
        margin: auto;
        padding: 0 1.5em;
        gap: 1.3em;
        max-width: 80em;
    }

    .footer h3 {
        font-size: clamp(1.3rem, 2.4vw, 2.25rem);
    }

    .footer h4 {
        margin: .2em 0 1em 0;
        font-size: clamp(1.1rem, 1.6vw, 1.8rem);
        font-weight: normal;
    }
   
    .footer ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .footer ul li {
        padding: .25em;
    }

    .footer a {
        color: inherit;
        text-decoration: none;
    }

    .footer ul li a:hover {
        opacity: .7;
    }
   
    .footer .icon {
        display: inline-block;
        padding-right: .5em;
    }

    .footer-social-list {
        text-align: center;
    }
   
    .footer-social-list ul {
        margin-top: 2em;
        font-size: clamp(1.1rem, 2vw, 1.5rem);
    }
   
    .footer-social-list ul li {
        padding: 0 .5em;
        display:inline;
    }
   
    .social-list__link {
        font-weight: var(--fw-bold);
    }

    .copyright {
        width: 100%;
        margin: 1em 0 0 0;
        padding: .75em;
        background-color: var(--clr-dark);
    }

    /* Resume page */
    #pdf_file {
        width: 80%;
        height: 55em;
    }
    /* End Resume page */

    /* Contact page */
    .form_contact {
        padding: 5em 0;
        background: rgb(81, 81, 81);
    }

    .form_contact h2 {
        text-transform: uppercase;
        text-align: left;
        margin-bottom: .5em;
    }

    form {
        padding: 1em;
        display: flex;
        flex-direction: column;
        width: min(90%, 45em);
        height: 30em;
        margin: 0 auto;
        background: rgb(81, 81, 81);
        font-family: Garamond, serif;
        color: white;
        box-shadow: .0125em .0125em .5em .0125em var(--clr-light);
        border-radius: .5em;
        border: 1px solid white;
    }
    
    form :is(input, textarea) {
        margin: .6em;
        padding: .5em;
        font-size: 1.25rem;
        font-family: var( --ff-primary);
        outline: none;
        border-radius: .4em;
        transition: 0.4s;
    }
    
    form input {
        height: 2.5em;
    }

    form input:focus, form textarea:focus  {
        border: .15em solid var(--clr-accent);
    }

    .error_message {
        width: 100%;
        height: 2em;
        padding-left: .5em;
        text-align: left;
        color: var(--clr-accent);
    }
    
    form button {
        margin: .6em;
        width: 7em;
        height: 1.8em;
        border-radius: .5em;
        font-size: 1.4rem;
        cursor: pointer;
        background-color: var(--clr-accent);
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        border: none;
        transition: 
            background-color ease-in .25s,
            color ease-in .25s,
            border ease-in .25s,
            box-shadow ease-in .25s;
    }

        form button:hover { /* ,
            form button:focus */
        background-color: #9a0008;
        border: .01em solid black;
        box-shadow: none;
        color: white;
    }
    /* End Contact page */

@media (max-width: 1125px) {

    #logo-image img {
        width: 6vw;
    }

    #header-grid-nav {
        grid-area: 2 / 1 / 3 / 4;
    }

    .nav__item {
        height: 1.8em;
    }

    .intro { 
        min-height: 0;
    }
}

@media (max-width: 768px) {

    .services {
        grid-template-columns: 48% 48%;
    }

    .portfolio {
        
        grid-template-columns: auto auto;
    }
}

@media (max-width: 512px) {
    .nav__link {
        font-size: clamp(.56rem, 1.4vw, 1.2rem);
    }

}

@media (max-width: 480px) {
    h1 {font-size: 8.4vw}

    /*Logo*/
    #logo {
        position: relative;
        width: 5em;
        height: 3em;
    }

    #logo-image img {
        left: 2.2em;
        position: absolute;
    }

    #logo-software {
        top: 1.8em;
        left: .8em;
        font-size: clamp(.5rem, 3vw, .8rem);
        transform: rotateX(45deg);
    }

    #logo-developer {
        top: 3.3em;
        left: 1.1em;
        font-size: clamp(.5rem, 3vw, .7rem);
        letter-spacing: .2rem;
        transform: rotateX(45deg);
    }
    /*End of logo*/

    /* header */
    header {
        position: fixed;
        top: 0;
        width: 100%;
        padding: .5em;
        height: 4em;
        background: var(--clr-dark);
        color: var(--clr-light);
        box-shadow: 0 .0125em .3em .05em white;
        z-index: 100;
    }

    #header-grid-container {
        display: grid;
        position: relative;
        grid-template-areas: 
        'header-grid-logo nav-toggle'
        'header-grid-logo header-grid-bsd';
        grid-template-columns: 25% 75%;
        grid-template-rows: 50% 50%;
    }
    
    .nav-toggle { 
        display: inline;
        grid-area: nav-toggle;
        position: absolute;
        cursor: pointer;
        width: 20%;
        height: 1.5em;
        right: 0;
    }

    #header-grid-logo {grid-area: header-grid-logo;}
    
    #header-grid-bsd {grid-area: header-grid-bsd;}

    .hamburger, .hamburgerx {
        display: block;
        position: absolute;
        top: .5em;
        right: 0;
    }
   
    .hamburger,
    .hamburger::before,
    .hamburger::after,
    .hamburgerx::before,
    .hamburgerx::after {
        background: var(--clr-accent);
        width: 2em;
        height: .1875em;
        border-radius: 1em;
    }
    
    .hamburger::before,
    .hamburger::after,
    .hamburgerx::before,
    .hamburgerx::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
    }

    .hamburger {
        width: 2em;
        transition: .6s ease-in-out;
    }

    .hamburgerx {
        width: 2em;
        border: 2px solid var(--clr-dark);
    }
    
    .hamburger::before { 
        top: .45em; 
        left: 0;
        width: 2em;
        transition: .6s ease-in-out;
    }
    .hamburger::after { 
        left: 0;
        width: 2em;
        bottom: .45em; 
        transition: .6s ease-in-out;
    }

    .hamburgerx::before {
        top: 0;
        left: 0;
        width: 2em;
        transform: rotate(45deg);
        transition: transform .6s ease-in-out;
    }

    .hamburgerx::after {
        top: 0;
        left: 0;
        width: 2em;
        transform: rotate(-45deg);
        transition: transform .6s ease-in-out;
    }

    .nav {
        position: absolute;
        width: 110%;
        height: 100vh;
        top: 2em;
        bottom: 0;
        left: -.8em;
        padding: 0;
        background: var(--clr-dark);
    }

    .nav__list {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 20em;
    }

    .nav__item {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        position: relative;
        height: 2em;
        width: 100%;
    }

    .nav__icon {
        position: absolute;
        width: 100%;
        top: 0;
        font-size: clamp(.9rem, 1.4vw, 1.2rem);
        color: rgb(48, 48, 48, 0);
        transition: color .6s ease-in;
    }

    .nav__link {
        position: absolute;
        bottom: 0;
        text-decoration: none;
        color: var(--clr-light);
        font-size: 1em;
        letter-spacing: .125em;
        text-transform: uppercase;
    }

    .intro { 
        margin-top: 5em;
    }

    #intro-container {
        width: 95%;
    }

    .section__subtitle--intro {
        font-size: 5vw;
    }
    
    .section__subtitle {
        font-size: clamp(1.3rem, 1.6vw, 2.5rem);
        margin: 0;
        padding: .5em;
    }

    .services {
        grid-template-columns: 100%;
        width: 95%;
    }

    .portfolio {
        
        grid-template-columns: auto;
    }

    .footer-grid-container {
        padding: 0 1em;
    }

    #pdf_file {
        margin-top: 5em;
        width: 95%;
        height: 27em;
    }
}